<template>
  <b-sidebar
    id="add-new-form-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Tạo mới {{ title }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationTitle"
            name="name"
            rules="required"
          >
            <b-form-group :label="`Tên ${title}`">
              <b-form-input
                id="name"
                v-model="params.name"
                autofocus
                :state="getValidationState(validationTitle)"
                :placeholder="placeholder"
              />

              <b-form-invalid-feedback>{{
                validationTitle.errors[0] ? `Bạn cần điền tên ${title}` : ""
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <validation-provider #default="validationOrder" name="order_selected" rules="required">
            <b-form-group label="Thứ tự hiển thị" v-if="type == 'category'" class="mb-0">
              <b-form-input
                type="number"
                id="order_selected"
                v-model="params.order_selected"
                :min="1"
                :state="isShowError"
                @input="handleCheckDuplicate"
                placeholder="Nhập thứ tự hiển thị"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationOrder.errors[0] ? `Bạn cần điền thứ tự hiển thị` : '' }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>-->
          <span
            v-if="showMessageError"
            class="text-danger"
            style="font-size: 12px; margin-top: 8px"
            >Số thứ tự này đã tồn tại, vui lòng nhập số thứ tự khác</span
          >
          <validation-provider name="view">
            <b-form-group
              label="Hiển thị trên Web"
              v-if="type == 'category'"
              class="mt-1"
            >
              <b-form-checkbox
                id="view"
                switch
                v-model="params.view"
              ></b-form-checkbox>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationAddress"
            name="address"
            rules="required"
          >
            <b-form-group
              label="Địa chỉ"
              v-if="
                type == 'manufacturer' || type == 'brand' || type == 'provider'
              "
            >
              <b-form-input
                v-model="params.address"
                :state="getValidationState(validationAddress)"
                placeholder="Nhập địa chỉ"
                id="address"
              />
              <b-form-invalid-feedback>{{
                validationAddress.errors[0] ? `Bạn cần điền địa chỉ` : ""
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationRepresentative"
            name="representative"
            rules="required"
          >
            <b-form-group
              label="Tên người đại diện"
              v-if="type == 'brand' || type == 'provider'"
            >
              <b-form-input
                id="representative"
                v-model="params.contact_name"
                :state="getValidationState(validationRepresentative)"
                placeholder="Nhập tên người đại diện"
              />
              <b-form-invalid-feedback>{{
                validationRepresentative.errors[0]
                  ? `Bạn cần điền tên người đại diện`
                  : ""
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div
            class="flex items-center justify-between gap-4"
            v-if="
              type == 'brand' || type == 'manufacturer' || type == 'provider'
            "
          >
            <validation-provider
              #default="validationPhoneNumber"
              name="phone_number"
              rules="required"
            >
              <b-form-group label="Số điện thoại">
                <b-form-input
                  id="phone_number"
                  v-model="params.phone_number"
                  :state="getValidationState(validationPhoneNumber)"
                  placeholder="Nhập số điện thoại"
                />
                <b-form-invalid-feedback>{{
                  validationPhoneNumber.errors[0]
                    ? `Bạn cần điền số điện thoại`
                    : ""
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationEmail"
              name="email"
              rules="required"
            >
              <b-form-group label="Email">
                <b-form-input
                  v-model="params.email"
                  :state="getValidationState(validationEmail)"
                  placeholder="Nhập email"
                  id="email"
                />
                <b-form-invalid-feedback>{{
                  validationEmail.errors[0] ? `Bạn cần điền email` : ""
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <b-form-group label="Mô tả">
            <b-form-textarea
              id="description"
              v-model="params.description"
              placeholder="Nhập mô tả"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading || isDisableBtn"
              >{{ isUpdate ? "Cập nhật" : "Tạo" }}</b-button
            >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              >Huỷ</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useToast } from "vue-toastification/composition";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewCategorySidebarActive",
    event: "update:is-add-new-category-sidebar-active",
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    isUpdate: Boolean,
    isLoading: Boolean,
    dataProp: Object,
    title: String,
    placeholder: String,
    type: String,
    options: Array,
  },
  data() {
    return {
      required,
    };
  },
  watch: {
    dataProp: {
      handler(value) {
        this.params = { ...value, view: value.view === 2 ? true : false };
        this.showMessageError = false;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isUpdate) {
      this.params = {
        ...this.dataProp,
        view: this.dataProp.view === 2 ? true : false,
      };
    } else
      this.params = {
        description: "",
        order_selected: "",
        phone_number: "",
        contact_name: "",
        email: "",
        address: "",
        name: "",
        view: true,
      };
  },
  setup(props, { emit }) {
    const toast = useToast();
    const params = ref({
      description: "",
      order_selected: "",
      phone_number: "",
      contact_name: "",
      email: "",
      address: "",
      name: "",
      view: true,
    });
    const showMessageError = ref(false);

    const resetuserData = () => {
      params.value = {
        description: "",
        order_selected: "",
        phone_number: "",
        contact_name: "",
        email: "",
        address: "",
        name: "",
        view: true,
      };
    };

    const isDisableBtn = computed(() => {
      if (
        props.type == "unit" ||
        props.type == "origin" ||
        props.type == "dosage-form" ||
        props.type == "specification"
      )
        return !params.value.name;
      if (props.type == "category")
        return (
          !params.value.name ||
          // !params.value.order_selected ||
          showMessageError.value
        );
      if (props.type == "manufacturer")
        return (
          !params.value.name ||
          !params.value.address ||
          !params.value.email ||
          !params.value.phone_number
        );
      if (props.type == "provider" || props.type == "brand") {
        return (
          !params.value.name ||
          !params.value.address ||
          !params.value.email ||
          !params.value.phone_number ||
          !params.value.contact_name
        );
      }
    });

    const onSubmit = () => {
      props.isUpdate
        ? emit("onUpdate", { ...params.value, id: props.dataProp.id })
        : emit("onAdd", params.value);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const handleCheckDuplicate = async (order) => {
      try {
        return await store
          .dispatch("configProduct/checkPriority", {
            priority: order,
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
          })
          .then((response) => {
            if (
              order === 0 ||
              order === "" ||
              order == props.dataProp.order_selected
            )
              showMessageError.value = false;
            else if (response?.data?.code === 200053)
              showMessageError.value = false;
            else {
              showMessageError.value = true;
            }
          });
      } catch (error) {
        console.log(error);
        showMessageError.value = true;
        return false;
      }
    };

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      store,
      params,
      isDisableBtn,
      handleCheckDuplicate,
      showMessageError,
    };
  },
  computed: {
    isShowError() {
      return this.params.order_selected !== "" && !this.showMessageError;
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-form-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
